export const cssId = (value: string) => `#${value}`
export const cssClass = (value: string) => `.${value}`
export const cssUrl = (value: string) => `url(${value})`
export const px = (value?: number) => `${value || 0}px`
export const pct = (value?: number) => `${value || 0}%`
export const fracToPct = (value?: number) => `${(value || 0) * 100}%`
export const translate = (x: number, y: number) => `translate(${x}px, ${y}px)`
export const dataAttr = (attr: string) => `[${attr}]`
export function multilineTextCss(lines: number) {
  return {
    "display": "-webkit-box",
    "-webkit-box-orient": "vertical" as any, // bc reasons
    "-webkit-line-clamp": lines,
    "overflow": "hidden",
  }
}
export function vueClass(...names: string[]) {
  return Object.fromEntries(names.map(names => [names, true]))
}

export function useLogger(tag: string, color = "#0496FF") {
  const prefix = [
    `%c${tag}`,
    `background: ${color}1A; color: ${color}; border-radius: 4px; padding: 1px 3px;`,
  ]
  return {
    ...console,
    debug: (...vals: any[]) => console.debug(...prefix, ...vals),
    log: (...vals: any[]) => console.log(...prefix, ...vals),
    info: (...vals: any[]) => console.info(...prefix, ...vals),
    warn: (...vals: any[]) => console.warn(...prefix, ...vals),
    error: (...vals: any[]) => console.error(...prefix, ...vals),
    trace: (...vals: any[]) => console.trace(...prefix, ...vals),
    time: (...vals: any[]) => console.time(...prefix, ...vals),
    timeLog: (...vals: any[]) => console.timeLog(...prefix, ...vals),
    timeEnd: (...vals: any[]) => console.timeEnd(...prefix, ...vals),
  }
}

export const clone = (value: object) => JSON.parse(JSON.stringify(value))
export const str = (value?: object) => JSON.stringify(value)
export function round(v: number, places = 3) {
  return v?.toFixed ? +v.toFixed(places) : v
}
export const zeroPad = (num: number, places: number) => String(num).padStart(places, "0")
export function roundedStr(value: object) {
  return JSON.stringify(value, (k, v) => round(v))
}
export const preload_img = (url: string) => (new Image().src = url)
export function clamp(v: number, min = 0, max = 1) {
  return Math.max(min, Math.min(v, max))
}

export const mean = (a: number, b: number) => (a + b) / 2

export function len(obj: object | Iterable<any>) {
  if (Array.isArray(obj))
    return obj.length
  else if (obj instanceof Set)
    return obj.size
  else return Object.keys(obj).length
}
/** Partitions string at the first occurrence of a separator */
export function partition(str: string, separator: string): [string, string] {
  const [first, ...rest] = str.split(separator)
  return [first, rest.join(separator)]
}

export function first<T>(obj: T[] | Record<any, T>): T {
  if (Array.isArray(obj))
    return obj[0]
  return Object.values(obj)[0]
}
export function last<T>(obj: T[]): T {
  return obj[obj.length - 1]
}
export function* zip<T extends any[][]>(...args: T) {
  const nArgs = Math.min(...args.map(e => e.length))
  for (let i = 0; i < nArgs; ++i) {
    yield args.map((e) => { return e[i] }) as { [I in keyof T]: T[I][number] }
  }
}
export const empty = (obj: object | Array<any> | Set<any>) => len(obj) === 0
export const peek = <T>(s: Iterable<T>): T => [...s][0]
export const all = <T>(s: Iterable<T>, f: (e: T) => any) => [...s].every(f)
export const any = <T>(s: Iterable<T>, f: (e: T) => any) => [...s].some(f)
export const is = (o: any) => (i: any) => i === o
export function map<T, V, O extends Record<any, T> | object | Iterable<T>>(
  obj: O,
  fn: (e: T) => V,
): Array<V> {
  if (Array.isArray(obj))
    return obj.map(fn)
  else if (obj instanceof Set)
    return [...obj].map(fn)
  else return Object.values(obj).map(fn)
}
export function mapRecord<K extends string | number | symbol, V, F>(
  obj: Record<K, V>,
  fn: (e: V) => F,
): Record<K, F> {
  return (Object.entries(obj) as ([K, V])[]).reduce((dict, [key, value]) => ({
    ...dict,
    [key]: fn(value),
  }), {} as Record<K, F>)
}
export function filtered<V extends object, K extends keyof V>(
  obj: V,
  fn: (e: V[K]) => boolean,
): V {
  return Object.keys(obj).reduce((result, key) => {
    // @ts-expect-error 'obj-assign'
    if (fn(obj[key]))
      // @ts-expect-error 'obj-assign'
      result[key] = obj[key]
    return result
  }, {} as V)
}
export const keys = <V extends Record<any, any>>(obj: V): Array<keyof V> => Object.keys(obj) as any
export const sortedByKey = <V extends Record<any, any>>(obj: V): Array<V[keyof V]> => keys(obj).sort().map(k => obj[k])
export const firstKeyInserted = <V extends Record<any, any>>(obj: V): keyof V | undefined => keys(obj).at(0)
export const lastKeyInserted = <V extends Record<any, any>>(obj: V): keyof V | undefined => keys(obj).at(-1)

export const range = (n: number) => [...Array(n).keys()]

// eslint-disable-next-line eqeqeq
export const enumEq = (a: any, b: any) => a == b

export function quickId() {
  return (Math.random() + 1).toString(36).substring(7)
}
export function randomANSIColor() {
  const randomHex = () => Math.floor(Math.random() * 256)
  return `\u001B[38;2;${randomHex()};${randomHex()};${randomHex()}m`
}
export function escapeANSIColor() {
  return "\u001B[0m"
}
export function reversed<T>(obj: Iterable<T>): Array<T> {
  return [...obj].reverse()
}
export function list<T extends object>(obj: Array<T> | T): Array<T> {
  return [...obj as Iterable<T>]
}
export function *enumerate<T>(obj: Array<T>): Generator<[number, T]> {
  for (let i = 0; i < obj.length; i++)
    yield [i, obj[i]]
}
export function dict<V extends Record<any, any>, K extends keyof V>(
  arr: V[],
  prop: K,
): Record<string, V> {
  return arr.reduce((obj, p) => ({
    ...obj,
    [p[prop]]: p,
  }), {} as Record<K, V>)
}
export function dictByKey<V extends Record<any, any>, K extends string | number | symbol>(
  arr: V[],
  prop: (item: V) => K,
): Record<string, V> {
  return arr.reduce((obj, p) => ({
    ...obj,
    [prop(p)]: p,
  }), {} as Record<K, V>)
}
export function groupedDict<V extends Record<any, any>, K extends keyof V>(
  arr: V[],
  prop: K,
): Record<string, V[]> {
  return arr.reduce((obj, p) => ({
    ...obj,
    [p[prop]]: [...(obj[p[prop]] || []), p],
  }), {} as Record<K, V[]>)
}
export function shuffled(array: Array<any>) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

export const isObject = (item: any) => (item && typeof item === "object" && !Array.isArray(item))

/**
 * Deep merge two objects.
 */
export function deepMerge(target: Record<any, any>, ...sources: any[]) {
  if (!sources.length)
    return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key])
          Object.assign(target, { [key]: {} })
        deepMerge(target[key], source[key])
      }
      else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return deepMerge(target, ...sources)
}

export function deepEqual(me: object, other: object) {
  return JSON.stringify(me) === JSON.stringify(other)
}

export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms))

// React-style helpers
export function withSlot<PropType extends Record<string, any>>(
  render: (slot: VNode[] | undefined, props: any) => VNode,
  props?: PropType,
) {
  return defineComponent({
    props: props || {},
    setup: (props, { slots }) => () => render(slots.default?.(), props),
  })
}

export function generator<T>(i: Iterable<T>) {
  async function* gen() {
    for (const e of i)
      yield e
  }
  return gen()
}

export async function iterator<T>(g: AsyncGenerator<T, void, unknown>) {
  const items = []
  for await (const i of g)
    items.push(i)
  return items
}

/**
 * This method is a way to map an async method over an async generator, which
 * could itself be a mapped pool, etc. This works by sequentially awaiting the
 * results of the input generator then maintaining a promise pool to execute the
 * process method with a max concurrency.
 *
 * We can do a few more things with this pattern:
 * 1. We can use this to do a simple `Promise.all` style function over an async
 * generator. Just by setting poolSize to -1 and process to `i => i`
 * 2. We can chain multiple mappers while maintain a peak concurrency, by chaining
 * map pool generators.
 * @param input An async generator that either wraps an iterable or is another
 * chained function
 * @param process a function to map over the iterable
 * @param poolSize the size of our process pool, which you can set to -1 for a
 * non-pooled chain (executes all promises at once). Otherwise, will only execute
 * `process` with this much concurrency
 */
export async function* mapPool<V, T>(input: AsyncGenerator<T, void, unknown>, process: (input: T) => Promise<V>, poolSize: number) {
  const pool: Record<string, Promise<[string, V]>> = {}
  let i = 0
  for await (const num of input) {
    // if pool == poolsize, we need to wait for something to finish in the pool
    if (Object.keys(pool).length === poolSize) {
      const [id, output] = await Promise.race(Object.values(pool))
      delete pool[id]
      yield output
    }
    const k = i.toString()
    pool[k] = new Promise((resolve, reject) => {
      process(num).then(v => resolve([k, v])).catch(reject)
    })
    i++
  }
  while (Object.keys(pool).length) {
    const [id, output] = await Promise.race(Object.values(pool))
    delete pool[id]
    yield output
  }
}

/**
 * Resolves a promise or errors if the promise takes longer than `ms` milliseconds
 * to resolve
 * @param promise a promise to await
 * @param ms timeout in milliseconds
 * @returns a promise that is resolved to the original promise or rejected if it
 * takes longer than `ms` milliseconds
 */
export async function withTimeout<T>(promise: Promise<T>, ms: number) {
  let timeoutId: number
  const timer = new Promise<T>((resolve, reject) => {
    timeoutId = setTimeout(() => reject(new Error("timed out")), ms) as any
  })
  return Promise
    .race([promise, timer])
    .then((result) => {
      clearTimeout(timeoutId)
      return result
    })
}

export async function loadImage(src: string, image?: HTMLImageElement) {
  image = image || new Image()
  return new Promise<HTMLImageElement>((resolve, reject) => {
    image.onload = () => resolve(image)
    image.onerror = () => reject(image)
    image.src = src
  })
}

/**
 * Tries to load an image from the cache, which basically involves trying to load
 * it but timing out quickly and removing the src attribute of the image (which
 * cancels the load operation if possible).)
 * @returns a promise that resolves to the Image element
 */
export async function loadImageFromCache(
  src: string,
  image?: HTMLImageElement,
  timeout: number = 25,
) {
  image = image || new Image()
  try {
    return await withTimeout(loadImage(src, image), timeout)
  }
  catch (error) {
    image.src = ""
    throw new Error("Could not load image")
  }
}

export function formatBytes(bytes: string | number | undefined, decimals: number = 0) {
  if (!bytes)
    return "0 Bytes"
  bytes = typeof bytes === "string" ? Number.parseFloat(bytes) : bytes
  const k = 1024
  const dm = decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${Number.parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

/**
 * Computes min value
 * @param items a list of items to get the min value of
 * @param distance a function that computes a value's distance
 * @returns a min item
 */
export function min<V>(
  items: V[],
  distance: (item: V) => number | undefined,
): V | undefined {
  let minDist = Number.MAX_SAFE_INTEGER
  let minItem: V | undefined
  for (const item of items) {
    const dist = distance(item)
    if (dist === undefined)
      continue
    if (dist >= minDist) { // too high
      continue
    }
    else {
      minItem = item
      minDist = dist
    }
  }
  return minItem
}

/**
 * Get a list of minimal elements, for when there are multiple elements that all
 * satisfy the min condition.
 * @param items A list of items to get the minimum for
 * @param distance a function that gives the distance for each item. A result of
 * undefined means skip the item
 * @param tolerance the tolerance of this function in case we have rounding issues
 */
export function minItems<V>(
  items: V[],
  distance: (item: V) => number | undefined,
  tolerance = 0,
): V[] {
  let minDist = Number.MAX_SAFE_INTEGER
  let minItems: V[] = []
  for (const item of items) {
    const dist = distance(item)
    if (dist === undefined)
      continue
    if (dist > minDist + tolerance) { // too high
      continue
    }
    else if (dist >= minDist - tolerance) { // within tolerance both ways
      minItems.push(item)
      continue
    }
    else {
      minItems = [item]
      minDist = dist
    }
  }
  return minItems
}

/**
 * Recursively searches an element's parents for whether it satisfies a certain
 * condition. Useful for grabbing details from an event.
 *
 * @param target The HTML element to query
 * @param fn a function to execute over recursive parents
 * @returns a div, if found, otherwise undefined
 */
export function getFirstMatchingParent(target: EventTarget | HTMLElement | null, fn: (t: HTMLElement) => boolean) {
  if (!target)
    return undefined
  let element = target as HTMLElement
  while (!fn(element)) {
    element = element.parentNode as HTMLElement
    if (!element)
      return undefined
  }
  return element
}
