function generateTheme() {
  return {
    /**
     * @deprecated use theme variables instead when possible
     */
    fontSize: {
      "3xl": ["28px", {
        "font-weight": "400",
        "letter-spacing": "0.025em",
        "line-height": "1.75",
      }],
      // 2xl is the search bar font
      "2xl": ["20px", {
        "font-weight": "280",
        "letter-spacing": "0.025em",
        "line-height": "1.75",
      }],
      "xl": ["16px", {
        "font-weight": "300",
        "letter-spacing": "0.025em",
        "line-height": "1.5",
      }],
      "lg": ["14px", {
        "font-weight": "320",
        "letter-spacing": "1%",
        "line-height": "1.5",
      }],
      "base": ["13px", {
        "font-weight": "340",
        "letter-spacing": "0.025em",
        "line-height": "1.5",
      }],
      "sm": ["12px", {
        "font-weight": "360",
        "letter-spacing": "0.025em",
        "line-height": "1.5",
      }],
      "xs": ["11px", {
        "font-weight": "380",
        "letter-spacing": "0.025em",
        "line-height": "1.5",
      }],
      "2xs": ["10px", {
        "font-weight": "400",
        "letter-spacing": "0.025em",
        "line-height": "1.5",
      }],
      "3xs": ["9px", {
        "font-weight": "400",
        "letter-spacing": "0.025em",
        "line-height": "1.5",
      }],
    },
    boxShadow: {
      menu: "0px 0px 20px 0px rgba(0, 0, 0, 0.30)",
    },
    borderRadius: {
      xl: "12px",
      lg: "10px",
      md: "8px",
      sm: "4px",
    },
    colors: {
      purple: {
        hex: "#9340D5",
        light: "#9340D533",
        stroke: "#9340D51A",
      },
      violet: {
        hex: "#958EEA",
        light: "#958EEA33",
        stroke: "#958EEA1A",
      },
      blue: {
        hex: "#0496FF",
        light: "#0496FF33",
        stroke: "#0496FF1A",
      },
      teal: {
        hex: "#00AA96",
        light: "#00AA9633",
        stroke: "#00AA961A",
      },
      green: {
        hex: "#00AC3A",
        light: "#00AC3A33",
        stroke: "#00AC3A1A",
      },
      amber: {
        hex: "#FFAE00",
        light: "#FFAE0033",
        stroke: "#FFAE001A",
      },
      pink: {
        hex: "#F12B82",
        light: "#F12B8233",
        stroke: "#F12B821A",
      },
      red: {
        hex: "#F13342",
        light: "#F1334233",
        stroke: "#F133421A",
      },
      /**
       * @deprecated use theme variables instead when possible
       */
      text: {
        primary: "#FAFAFA",
        secondary: "#9597A2",
        tertiary: "#52525B",
        quaternary: "#3A393F",
      },
      /**
       * @deprecated use theme variables instead when possible
       */
      background: {
        // primary: '#0E0D0D',
        // secondary: '#222227',
        tertiary: "#39393E",
        window: "#222227A8",
        browser: "#222227",
      },
      /**
       * @deprecated use theme variables instead when possible
       */
      accent: {
        strongest: "#37373DB3",
        neutral: "#222224B3",
        weakest: "#1C1B1E40",
      },
      /**
       * @deprecated use theme variables instead when possible
       */
      stroke: {
        darkest: "#52525B",
        darker: "#71717A",
        dark: "#A1A1AA",
        neutral: "#D4D4D8",
        light: "#E4E4E7",
        lighter: "#F4F4F5",
        lightest: "#FAFAFA",
      },

    },
    zIndex: {
      "gesture-layer": "100",
      "gesture-overlay-layer": "110",
      "title-bar-layer": "200",
      "navigation-layer": "300",
      "overlay-layer": "400",
      "search-layer": "500",
      "drag-layer": "600",
    },
  }
}

export default generateTheme()
